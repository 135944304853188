const skills = [
    {
        'title': 'Strengths',
        'heading1': 'Outgoing',
        'heading2': 'Strong Leader',
        'heading3': 'Independent',
        'heading4': 'Highly motivated',
        'heading5': 'Creative',
    },
    {
        'title': 'Hobbies',
        'heading1': 'Programming',
        'heading2': 'Kickboxing',
        'heading3': 'Running',
        'heading4': 'Gym',
        'heading5': 'Socialising',
    },
    {
        'title': 'Goals',
        'heading1': 'Complete freelance projects',
        'heading2': 'Contribute to an open-source project',
        'heading3': 'Travel',
        'heading4': 'Publish an app',
        'heading5': 'Help others',
    },

];

export default skills;