import React, { Component } from 'react'
import me from '../assets/me.jpg';
import background from '../assets/aboutMe.jpg';
import aboutMe from '../dataModels/aboutMeModel';

export class AboutMePage extends Component {
    render() {
        return (
            <div className="py-5 text-white" id="AboutMePage" style={{ 'backgroundImage': `linear-gradient(to right, rgba(0, 0, 0, .75), rgba(0, 0, 0, .75)), url(${background})`, 'backgroundRepeat': 'repeat, repeat', 'backgroundPosition': 'center center, center center', 'backgroundSize': 'cover, cover' }}>
                <div className="container wow fadeInRight">
                    <div>
                        <div>
                            <div className="jason-img">
                                <img alt='me' src={me} className="jason" />
                            </div>
                        </div>

                        <div className="jason-text">
                            <h2 className="about-text">About Me</h2>
                            <p className="lead mb-0"> {aboutMe.paragraph1}</p>
                            <div style={{ 'height': '20px' }} />
                            <p className="lead mb-0" style={{ 'paddingBottom': '20px' }}> {aboutMe.paragraph2}</p>

                            <ul className="languages">
                                {aboutMe.languages.map(function (item, index) {
                                    return <li key={index}>{item}</li>
                                })}
                            </ul>

                            <a href="#strengths" className="btn btn-lg mt-4 btn-primary shadowed wow fadeInLeft"
                                data-wow-delay=".3s">Continue</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutMePage
