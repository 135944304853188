import React, { Component } from 'react'
import projects from '../dataModels/projectsModel';
import ProjectCard from './cards/projectCard';

export class ProjectsPage extends Component {
    render() {
        return (
            <div className="py-5" id="Projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Projects</h1>
                        </div>
                    </div>
                </div>

                <div className="row wow bounceInRight" data-wow-delay=".2s">
                    {projects.map(function (project, index) {
                        return <ProjectCard key={index}
                            title={project.title}
                            imgUrl={project.imgUrl}
                            description={project.description}
                            gitHubUrl={project.gitHubUrl}
                            liveUrl={project.liveUrl}
                        />
                    })}
                </div>
            </div>
        )
    }
}

export default ProjectsPage;
