import React, { Component } from 'react'
import background from '../assets/skills.jpg';
import StrengthCard from './cards/strengthCard';
import skills from '../dataModels/skillsModel';

export class StrengthsSkillsPage extends Component {
    render() {
        return (
            <div className="py-5" id="strengths" style={{ 'backgroundImage': `url(${background})` }}>
                <div className="container my-5">
                    <div className="row">
                        <div className="col-12">
                            <h1>About Me</h1>
                        </div>
                    </div>
                    <div className="row text-left">
                        {skills.map(function (item, index) {
                            return <StrengthCard key={index}
                                title={item.title}
                                heading1={item.heading1}
                                heading2={item.heading2}
                                heading3={item.heading3}
                                heading4={item.heading4}
                                heading5={item.heading5} />
                        })}
                    </div>

                    <a href="#Projects" className="btn btn-lg mt-4 btn-primary shadowed wow fadeInLeft" data-wow-delay=".3s">View my
        Projects</a><i className="d-block fa fa-angle-down pt-7 fa-3x"></i>
                </div>
            </ div >
        )
    }
}

export default StrengthsSkillsPage
