import React, { Component } from 'react'

export class ProjectCard extends Component {
    render() {
        return (
            <div className="col-sm-6">
                <div className="card">
                    <div className="card-body">
                        <div className="img-title-div">
                            <img src={this.props.imgUrl} className="card-img-bottom" style={{ 'width': 'auto' }} alt="android" />
                            <h5 className="card-title" style={{ 'paddingTop': '12px' }}>{this.props.title}</h5>
                        </div>
                        <p className="card-text" style={{ 'paddingBottom': '0px' }}> {this.props.description}</p>
                        {this.props.gitHubUrl && <a href={this.props.gitHubUrl} target="_blank" className="btn btn-primary" rel="noreferrer">Download here</a>}
                        <div style={{ height: '10px' }}></div>
                        {this.props.liveUrl && <a href={this.props.liveUrl} target="_blank" className="btn btn-primary" rel="noreferrer">View Live Demo</a>}
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectCard;
