import React from 'react';


const NavBar = () => {

    return (
        <>
            <div className="fixed-top notification-banner">Hey everyone, this website will soon be undergoing a complete redesign. Please check back soon for updates :)</div>
            <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
                <div className="container">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navbar2SupportedContent" aria-controls="navbar2SupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbar2SupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item mx-2 text-primary">
                                <a className="nav-link" href="#Home">Home</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link" href="#AboutMePage">About Me</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link" href="#Projects" suppressContentEditableWarning={true} contentEditable="false">Projects</a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link" href="#Social" suppressContentEditableWarning={true} contentEditable="false">Social Media</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavBar;
