import aquaSafeImg from '../assets/AquaSafe.png';
import instaAnalyticsImg from '../assets/instagramAppMockup.png';
import ebayDealsImg from '../assets/ebayDeals.png';
import tweetSearchImg from '../assets/twitter_tool.png';
import folderOrganiserImg from '../assets/folder_organiser.png';
import CodeForumImg from '../assets/CodeForum.png';
import PhotographyPortfilioImg from '../assets/photographyPortfolio.png';

const projects = [
    {
        'title': 'Photography Portfolio',
        'imgUrl': PhotographyPortfilioImg,
        'description': 'I designed and developed a portfolio website from the ground up using Vue.js for the frontend and Firebase cloud storage for the backend. This project was created for a professional photographer to provide her with a user-friendly platform to showcase her work to potential clients. This experience significantly enhanced my ability to understand client requirements and deliver a product that aligns with their needs.',
        'liveUrl': 'https://rachelcaughers.co.uk'
    },
    {
        'title': 'CodeForum',
        'imgUrl': CodeForumImg,
        'description': 'This chatroom platform is designed specifically for programmers, providing a space to ask coding-related questions and engage with a supportive community. The platform features separate rooms for each programming language and is easily accessible via Google account login. I developed CodeForum using React for the frontend and Firebase for the backend. This project was a fun and educational way for me to deepen my knowledge of the React framework and further test my development skills.',
        'gitHubUrl': 'https://github.com/jasonc2901/CodeForum',
        'liveUrl': 'https://modest-khorana-13eb39.netlify.app/'
    },
    {
        'title': 'AquaSafe',
        'imgUrl': aquaSafeImg,
        'description': 'This iOS/Android app addresses the critical issue of water pollution in the UK by providing users with comprehensive pollution data for rivers across Northern Ireland and the Republic of Ireland. Both governments supported the project by supplying real-world data. The final product is a user-friendly Flutter app powered by a Python Flask REST API, which delivers river data, scrapes pollution-related news, and submits pollution reports to a PostgreSQL database. The project has garnered interest from the Environmental Protection Agency in Southern Ireland.',
        'gitHubUrl': 'https://github.com/jasonc2901/AquaSafe'
    },
    {
        'title': 'Full Stack Instagram Analytics App',
        'imgUrl': instaAnalyticsImg,
        'description': 'This project is my second attempt of building a full stack application. I designed and built a Python Rest API from scratch using Flask and implemented a smart Instagram data retrieval method using the Instaloader third party module, I opted for a third party resource as the Instagram Official API does not have the features I needed. I then built out the frontend Application using the Flutter/Dart programming language and connected it to the rest api using the BLoC pattern method. Feel free to download the code from my GitHub and use the app for free!',
        'gitHubUrl': 'https://github.com/jasonc2901/Instagram_Analytics'
    },
    {
        'title': 'eBay Top Deals - Python GUI',
        'imgUrl': ebayDealsImg,
        'description': "This project is a Python web scraper that retrieves new deals everyday and displays the top ones in a Tkinter GUI. I used the BeautifulSoup4 and request modules to do the web scraping and retrieve product details from eBay's website, I then designed a Tkinter GUI that utlises all of this data and displays it to the user. I completed this tool in one day simply to challenge myself with a web scraping project, I also wanted more experience building GUI's with the Tkinter Module that comes pre-installed with Python 3.",
        'gitHubUrl': 'https://github.com/jasonc2901/eBay_deals_scraper'
    },
    {
        'title': 'Tweet Search Tool',
        'imgUrl': tweetSearchImg,
        'description': "This project is something I created because I found myself wanting to search for my old tweets, after looking into this i realised there was unfortunately no quick way of achieving what I wanted. This got me thinking of ways to improve this so I made a Python program using a combination of the Tweepy and Tkinter modules. The tool is essentially a GUI that retrieves data from the Twitter developer API and displays it to the user, it requires the user to have an existing Twitter developer account to gain access to their personal API Key and Access token.",
        'gitHubUrl': 'https://github.com/jasonc2901/Twitter_Api_Search_Tool'
    },
    {
        'title': 'Folder Organisation Tool',
        'imgUrl': folderOrganiserImg,
        'description': "This is a script I created to challenge myself using Python. I’ve been using this language a lot in my current job and been fascinated with the huge number of things I can create with it. This project is essentially a script that monitors a specific folder on my laptop for any changes, when a file is placed into this folder the script will check the file type by looking at the extension and determine which sub folder it should be placed into. This was a project I came up with myself and created the code from scratch. I have linked my GitHub account if you want to download this project and use it on your own devices.",
        'gitHubUrl': 'https://github.com/jasonc2901/PythonFolderOrganiser'
    }
];


export default projects;