import React, { Component } from 'react';
import background from '../assets/code.jpg';
import 'reactstrap';
import aboutMe from '../dataModels/aboutMeModel';

export class LandingPage extends Component {
    render() {
        return (
            <div className="d-flex align-items-center py-5 cover section-fade-in-out bg-dark"
                style={{ backgroundImage: `url(${background})` }} id="Home">
                <div className="container wow bounceInLeft">
                    <div className="row">
                        <div className="col-12 mt-5">
                            <h3 className="display-2" suppressContentEditableWarning={true} contentEditable="false">{aboutMe.intro}</h3>
                            <h1 className="display-10" suppressContentEditableWarning={true} contentEditable="false">{aboutMe.tagline}<br></br></h1>
                            <a href="#AboutMePage" className="btn btn-lg mt-4 btn-primary shadowed wow fadeInRight" data-wow-delay="0.7s">A
            bit about me</a><i className="d-block fa fa-angle-down pt-7 fa-3x"></i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LandingPage;
