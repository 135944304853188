import React from 'react';
import LandingPage from './components/LandingPage';
import NavBar from './components/Navbar';
import AboutMePage from './components/AboutMePage'
import StrengthsSkillsPage from './components/StrengthsSkillsPage';
import ProjectsPage from './components/ProjectsPage';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <NavBar />
      <LandingPage />
      <AboutMePage />
      <StrengthsSkillsPage />
      <ProjectsPage />
      <Footer />
    </div>
  );
}



export default App;
