import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div id='Social'>
                <div>
                    <a href="#Home" className="btn btn-lg mt-4 btn-primary shadowed wow fadeInLeft" data-wow-delay="0.6s">Return to top</a><i className="d-block fa fa-angle-up pt-7 fa-3x"></i>
                </div>

                <h2>Follow Me</h2>
                <a href="https://www.facebook.com/caughers" className="fa fa-facebook fa-3x"> </a>
                <a href="https://www.linkedin.com/in/jason-caughers" className="fa fa-linkedin fa-3x"> </a>
                <a href="https://www.instagram.com/jasoncaughers" className="fa fa-instagram fa-3x"> </a>
                <div className="row">
                    <div className="col-md-12">
                        <p className="text-muted">© Jason Caughers 2022.</p>
                    </div>
                </div>
            </div>

        )
    }
}

export default Footer;
