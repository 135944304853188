import React, { Component } from 'react'

export class StrengthCard extends Component {
    render() {
        return (
            <div className="p-4 col-lg-4 col-md-6 wow fadeInLeftBig" data-wow-delay="0.5s">
                <div className="card shadowed">
                    <div className="card-block text-center card-primary p-2">
                        <h2>My</h2>
                        <p className="lead-about">{this.props.title}</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item"><i className="mx-auto fa d-inline text-primary"></i>&nbsp;1.&nbsp;<b> {this.props.heading1}</b></li>
                        <li className="list-group-item"><i className="mx-auto fa d-inline text-primary"></i>&nbsp;2.&nbsp;<b>{this.props.heading2}</b></li>
                        <li className="list-group-item"><i className="mx-auto fa d-inline text-primary"></i>&nbsp;3.&nbsp;<b>{this.props.heading3}</b></li>
                        <li className="list-group-item"><i className="mx-auto fa d-inline text-primary"></i>&nbsp;4.&nbsp;<b>{this.props.heading4}</b></li>
                        <li className="list-group-item"><i className="mx-auto fa d-inline text-primary"></i>&nbsp;5.&nbsp;<b>{this.props.heading5}</b></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default StrengthCard
